import React from "react";

export default function blog() {
  return (
    <div>
      <section className="section place w-100 text-center" aria-label="">
      <div className="">
      <div className="">
            
            <h1 className="title-1 place-title-1"> </h1>
            <h1 className="title-1 place-title-1">Blog - Feltöltés alatt</h1>
      </div>
      </div>
      </section>
    </div>
  )
}
